import { Controller } from "stimulus"

export default class extends Controller {
  showForm() {
    const elements = $(this.element);
    elements.find('.risk-value').hide();
    elements.find('form').show();
  }
  hideForm() {
    const elements = $(this.element);
    elements.find('.risk-value').show();
    elements.find('form').hide();
  }
}