import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ];

  search(e) {
    if (e.target.type === 'radio') {
      $(this.formTarget).find("select").removeAttr('name');
    } else {
      $(this.formTarget).find("input[type='radio']").removeAttr('name');
    }
    this.formTarget.submit();
  }
}